<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>

    <Table
      ref="table"
      :headers="headers"
      :items="employees"
      :status="true"
      :delete_meg="'حذف الموظف ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_employee($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
      :id_dialog="id_dialog"
      @show_photo_id="id_dialog = true"
      @close_photo_id="id_dialog = false"
    ></Table>
    <!--table -->

    <BaseForm
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(employee_id = null), (title = 'اضافة موظف')"
      @reset_rules="reset_rules()"
      @close_snack="close_snack($event)"
    ></BaseForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import Table from "@/components/employees/table";
import BaseForm from "@/components/base/form";

export default {
  name: "employees",

  data() {
    return {
      rules_props: {
        phone_exist_prop: false,
        id_number_exist: false,
      },
      id_dialog: false,
      delete_dialog: false,
      employee_id: null,
      delete_loader: false,
      employees: [],
      title: "موظف جديد",
      data_loaded: false,
      form_obj: {
        name: {
          value: "",
          label: "اسم الموظف",
          type: "text",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },
        phone_number: {
          value: "",
          label: "رقم الهاتف",
          type: "number",
          is_require: true,
          rules: [
            (v) => !this.phone_exist || "موجود مسبقا",
            (v) => !!v || "حقل مطلوب",
          ],
        },
        id_number: {
          value: "",
          label: "رقم الهوية",
          type: "number",
          is_require: true,
          rules: [
            (v) => !this.id_exist || "موجود مسبقا",
            (v) => !!v || "حقل مطلوب",
          ],
        },
        country: {
          value: "",
          label: "البلد",
          type: "text",
          is_require: false,
        },
        address: {
          value: "",
          label: "العنوان",
          type: "text",
          is_require: false,
        },
        hour_price: {
          value: 0,
          label: "سعر الساعة",
          type: "text",
          is_require: false,
        },
        day_price: {
          value: 0,
          label: "سعر اليوم",
          type: "text",
          is_require: false,
        },
        id_img: {
          value: null,
          label: "صورة الهوية",
          type: "file",
          is_require: false,
        },
        password: {
          value: "",
          label: "كلمة المرور",
          type: "password",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },

        status: {
          value: true,
          label: ["فعال", "غير فعال"],
          type: "switch",
        },
      },
      headers: [
        {
          value: "name",
          text: "اسم الموظف",
          align: "center",
          sortable: false,
        },
        {
          value: "id_number",
          text: "رقم الهوية",
          align: "center",
          sortable: false,
        },
        {
          value: "country",
          text: "البلد",
          align: "center",
          sortable: false,
        },
        {
          value: "address",
          text: "العنوان",
          align: "center",
          sortable: false,
        },
        {
          value: "phone_number",
          text: "رقم الهاتف",
          align: "center",
          sortable: false,
        },
        {
          value: "hour_price",
          text: "سعر الساعة",
          align: "center",
          sortable: false,
        },
        {
          value: "day_price",
          text: "سعر اليوم",
          align: "center",
          sortable: false,
        },

        {
          value: "status",
          text: "الحالة",
          align: "center",
          sortable: false,
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
          sortable: false,
        },
      ],
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
    };
  },
  computed: {
    phone_exist() {
      return this.rules_props.phone_exist_prop;
    },
    id_exist() {
      return this.rules_props.id_number_exist;
    },
  },
  methods: {
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "employees",
            method: "get",
          },
        })
        .then((res) => {
          this.employees = Object.assign([], res.data.data);
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    edit_row(ev) {
      Object.keys(ev.item).forEach((e) => {
        if (this.form_obj[e]) {
          this.form_obj[e].value = ev.item[e];
        }
      });
      this.employee_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    reset_rules() {
      Object.keys(this.rules_props).forEach((e) => {
        this.rules_props[e] = false;
      });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    save_form() {
      this.save_loading = true;
      let formData = new FormData();
      Object.keys(this.form_obj).forEach((e, i) => {
        if (e != "status") {
          formData.append(
            e,
            !this.form_obj[e].value ? "" : this.form_obj[e].value
          );
        } else {
          formData.append(e, this.form_obj[e].value ? 1 : 0);
        }
      });

      this.$store
        .dispatch("public__request", {
          config: {
            url: this.employee_id
              ? `update_employee/${this.employee_id}`
              : `employees`,
            method: "post",
          },
          data: formData,
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة الموظف بنجاح";
          this.alerts.success = true;
          console.log(res);
          this.$refs.emForm.toggle_btn_form();
          this.get_employees();
        })
        .catch((err) => {
          console.log(err.response);
          this.save_loading = false;
          if (err.response.data.data.phone_number) {
            this.rules_props.phone_exist_prop = true;
          }
          if (err.response.data.data.id_number) {
            this.rules_props.id_number_exist = true;
          }
          this.alerts.error_text = "برجاء مراجعة البيانات";
          this.alerts.error = true;
          this.$refs.emForm.$refs.form.validate();
        });
    },

    delete_employee(employee_id) {
      if (employee_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `employees/${employee_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الموظف";
            this.delete_loader = false;
            this.get_employees();
            this.delete_dialog = false;
            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },
  components: {
    Table,
    BaseForm,
  },
  created() {
    this.get_employees();
  },
};
</script>
